@import "./variables.scss";
@import "./navbar.scss";
@import "./footer.scss";
@import "./fourohfour.scss";
@import "./profilesection.scss";
@import "./projects.scss";
@import "./technologies.scss";
@import "./resume.scss";

body {
  font-family: $body-font;
}

.fab,
.fas {
  color: $secondary-color;
  font-size: 34px;
  padding-left: 18px;

  &:hover {
    color: $primary-color;
  }
}

a,
a:hover,
a:active,
a:visited {
  color: $secondary-color;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  font-family: $header-font;
}

.resume {
  display: grid;
  justify-items: center;
  row-gap: 18px;

  a{

    background-color: $primary-color;
    border-radius: 8px;
    font-weight: 700;
    color:white;
    padding: 20px;

  }


}
