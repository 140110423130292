@import "./variables.scss";

.profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-height: 78vh;

  @media (max-width: 425px) {
    display: grid;
    justify-items: center;
  }

  &__headshot {
    max-width: 600px;
    max-height: 400px;
    border-radius: 10px;

    @media (max-width: 425px) {
      max-width: 400px;
      max-height: 266px;
    }
    @media (max-width: 320px) {
      max-width: 266px;
      max-height: 177px;
    }
  }

  &__bio--text {
    font-size: 20px;
    margin-left: 20px;
    max-width: 500px;
    line-height: 1.6;

    @media (max-width: 425px) {
      max-width: 333px;
    }
    @media (max-width: 320px) {
      max-width: 222px;
    }

    span {
      color: $primary-color;
      font-weight: 500;
    }

    a {
      text-decoration: underline;
      color: $secondary-color;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
        color: $primary-color;
        font-weight: 500;
      }
    }
  }
}
