@import "./variables.scss";

footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;

  a {
    margin-top: 20px;
  }
}
