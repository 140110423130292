@import "./variables.scss";

.project-grid {
  display: grid;
  margin-top: 10px;
  justify-content: center;
  max-width: 1080px;
  margin: auto;
  padding: 0 1em;

  &__tile {
    display: flex;
    color: $secondary-color;
    justify-content: center;
    align-content: center;
    row-gap: 8px;
    border-bottom: 1px solid $secondary-color;
    padding: 1em 0;

    @media (min-width: 769px){

        &:nth-child(odd) .project-grid__tile--image {
          order: 2;
        }
    }

    @media (max-width: 768px){
        display: grid;
    }

    

    &--image {
      display: grid;
      justify-items: center;
      row-gap: 8px;
      box-shadow: 0px 0px 30px 0px #0000001a;
      margin: 0 1em;

      &:hover {
        color: $primary-color;
        font-weight: 700;
      }

      img {
        max-width: 600px;
        border-radius: 8px;

        @media (max-width: 425px){
            max-width: 75vw;
        }
      }
    }

    &--text {
      display: grid;
      align-content: center;
      margin-left: 3px;

      h2 {
        margin-bottom: 0;

        &:hover{
            color: $primary-color;
        }
      }


    }
  }
}
