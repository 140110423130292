@import "./variables.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 8px;

  @media (max-width: 710px) {
    display: grid;
    justify-items: center;
  }

  h1 {
    color: $primary_color;
    font-size: 35px;
    margin: 3px;
    margin-left: 10px;
  }

  &__right {

    @media (max-width: 710px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    a {
      padding: 6px;
      margin-left: 3px;
      margin-right: 10px;
      font-weight: 700;

      &:hover {
        color: $primary-color;
        transition: 0.5s ease-in-out;
      }
    }
  }
}
