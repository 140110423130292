@import "./variables.scss";

.tech-header{
    margin-left: 18px;
    width: fit-content;
    color: $secondary-color;
    margin: auto;

    @media (max-width: 425px){
        max-width: 75vw;
    }
}

.tech-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    justify-items: center;
    row-gap: 50px;
    column-gap: 50px;
    padding: 10px;
    max-width: 1080px;
    margin: auto;

    @media (max-width: 1024px){
        max-width: 75vw;
    }

    @media (max-width: 425px){
        max-width: 75vw;
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    }
    @media (max-width: 320px){
        grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
    }


    i{
        font-size: 100px;
        margin-left: 100px;
        margin-right: 100px;

        @media (max-width: 425px){
            font-size: 50px;
            margin-left: 50px;
            margin-right: 50px;
        }

    }
}